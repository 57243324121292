import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { ProjectCard2 } from "./ProjectCard2";
import projImg1 from "../assets/img/project-img1.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import projImg7 from "../assets/img/project-img7.png";
import projImg10 from "../assets/img/project-img10.png";
import projImg12 from "../assets/img/project-img12.png";

import projImg20 from "../assets/img/project-img20.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "\"Hands\" series",
      description: "Poker Hands and Blackjack Hands",
      description2: "A card based puzzle game made in Unity Game Engine with C#.",
      // description2: "",
      // description3: "\nSkills used: \n- Unity\n- C#\n- Version Control (git) \n\nSkills learned:\n- Team work\n- Prodution pipeline",
      description3: "Role: Game developer/programmer\n\nThese projects have sentimental value to me as they were my first projects that I did right after software engineering college and I got my first job. \nOn these projects, I worked closely with the senior developer on the team, handling all game systems and mechanics myself, but doing code reviews at the end of each day, this approach allowed me to quickly learn the full workings of the Unity engine, improve my fluency in C#, deeply understand the benefits of version control, learn that teamwork is essential, and understand all stages of game development from pre-production to post-launch support.\n\nProject duration: 1 month for each\n\nSkills: \n- Unity\n- C#\n- Version Control (git)",
      description4: "\nPoker hands on Steam",
      description5: "Blackjack Hands on Steam",
      imgUrl: projImg1,
      link: "https://store.steampowered.com/app/1261670/Poker_Hands/?curator_clanid=37117656",
      link2: "https://store.steampowered.com/app/1292250/Blackjack_Hands/?curator_clanid=37117656",
    },    
    {
      title: "Knight's Retreat",
      description: "",
      description2: "A chess based puzzle game made in Unity Game Engine with C#.",
      description3: "Role: Game Developer/Programmer\n\nThis was my second project where the focus was on quality assurance; with close interactions with the game designer, we did several tests to find bugs, inconsistencies and optimization points throughout the project, using tools that we had at our disposal or developing tools when necessary. \n\nProject duration: 1 month\n\nSkills: \n- Unity\n- C#\n- Version Control (git)\n- Quality assurance\n- Tooling",
      description4: "\nLink to Steam",
      imgUrl: projImg12,
      link: "https://store.steampowered.com/app/1261700/Knights_Retreat/?curator_clanid=37117656",
    },
    {
      title: "Chess Knights: Viking Lands",
      description: "",
      description2: "A chess based puzzle game made in Unity Game Engine with C# themed in nord culture.",
      description3: "Role: Game Developer/Programmer\n\nThe first of the Chess knights series. I was assigned to this project after the release and the goal was to making a level expansion system adjacent to the main course of the game and with close interactions with the game designer and artist we achieved the goal of making the DLC within the deade line.\n\nProject duration: 1 month\n\nSkills: \n- Unity\n- C#\n- Version Control (git)\n- Quality assurance",
      description4: "\nLink to Steam",
      imgUrl: projImg3,
      link: "https://store.steampowered.com/app/1338980/Chess_Knights_Viking_Lands/?curator_clanid=37117656",
    },
    {
      title: "Chess Knights: Shinobi",
      description: "",
      description2: "A chess based puzzle game made in Unity Game Engine with C# themed in oriental culture.",
      description3: "Role: Game Developer/Programmer\n\nThe second of the Chess knights series. Again with close interactions with the game designer and artist we achieved the goal of making a levels expansion subsystem adjacent to the main course of the game as well making the final credits animation, quality assurance of the product; shortly after launch I was reassigned to this project to integrate eastern Localizations on it.\n\nProject duration: 1 month\n\nSkills: \n- Unity\n- C#\n- Version Control (git)\n- Quality assurance \n- Localization API (I2Loc)",
      description4: "\nLink to Steam",
      imgUrl: projImg4,
      link: "https://store.steampowered.com/app/1383050/Chess_Knights_Shinobi/?curator_clanid=37117656",
    },
    {
      title: "Chess Knights: High Noon",
      description: "",
      description2: "A chess based puzzle game made in Unity Game Engine with C# themed in old west culture.",
      description3: "Role: Game Developer/Programmer\n\nThe third in the Chess knights series. This project was a milestone and a challenge for me, it was the first project I took on as the main technology developer, so the entire pre-production, production and delivery process was coordinated by me, with close interactions with all other disciplines, we achieved the goal of create gameplay, animations, cinematics, audio integration, auxiliary systems and quality assurance. The game was published in early access, and we continue to work on the main story expansions, but I was assigned to other projects before it was finished.\nProject duration: 3 months\n\nSkills: \n- Project management (Tech) \n- Unity\n- C#\n- Version Control (git)\n- Quality assurance\n- Tooling\n- Wwise API",
      description4: "\nLink to Steam",
      imgUrl: projImg5,
      link: "https://store.steampowered.com/app/1486800/Chess_Knights_High_Noon/?curator_clanid=37117656",
    },
    {
      title: "The Chess Variants Club",
      description: "",
      description2: "A chess game made in Unity Game Engine with C#, with the main objective of showing the chess variants to the players.",
      description3: "Role: Game Developer/Programmer\n\nIn this game, my main duty was to learn and modify an existing chess AI to support a series of chess variants in its calculations and create a puzzle system using this AI as a base to make the creation and puzzle solving of each variant very dynamic.\nProject duration: 1 month\n\nSkills:\n- Unity\n- C#\n- Version Control (git)\n- Quality assurance\n- Tooling\n- A.I. programing",
      description4: "\nLink to Steam",
      imgUrl: projImg6,
      link: "https://store.steampowered.com/app/1292240/The_Chess_Variants_Club/?curator_clanid=37117656",
    },
    {
      title: "\"Chess Games\" series",
      description:"Samurai chess, Wild wild chess and Ragnarok chess",
      description2: "A chess game made in Unity Game Engine with C#, to deliver thematic experiences for players who identify with the theme.",
      description3: "Role: Game Developer/Programmer\n\n Another milestone for me, in this project the intention was to make 3 identical games and have a theme variation between them to converse with diferent types of players, I was assigned as the only developer for a project for the first time, so I had the opportunity to work again with project management in the tech side and develop all the systems for gameplay, UI, animations and audio; But the highlight was the decision of making a tool in the Unity Editor to switch between themes easily allowing the use of the same base for the 3 games cutting production time by two thirds. \n\nProject duration: 1 month. \n\nSkills: \n- Project management (Tech) \n- Unity\n- C#\n- Version Control (git)\n- Quality assurance\n- Tooling\n- Wwise API",
      description4: "\nSamurai chess on Steam",
      description5: "Wild Wild Chess on Steam",
      description6: "Ragnarok Chess on Steam",
      imgUrl: projImg7,
      link: "https://store.steampowered.com/app/1568330/Samurai_Chess/?curator_clanid=37117656",
      link2: "https://store.steampowered.com/app/1582090/Wild_Wild_Chess/?curator_clanid=37117656",
      link3: "https://store.steampowered.com/app/1593180/Ragnarok_Chess/?curator_clanid=37117656",
    },
    {
      title: "Jigsaw chess tale's",
      description: "Shinobi's Way and Jessie 'Boom' James",
      description2: "An jigsaw puzzle with chess-shaped pieces made in Unity Game Engine with C#, that deliver's a unique new challenge and thematic experience for players who identify with the theme.",
      description3: "Role: Game Developer/Programmer\n\nFollowing the same idea of the previous Chess games in this project the intention was to make 2 identical games and have a variation of theme between them to talk with different types of players, again I was designated as the sole developer of the project, and the same skills were used, because the last project had a similar structure, the only difference was that in this project I had to experiment with developing a different type of gameplay than what I was used to, it was a nice change of pace and a good challenge. \n\nProject duration: 1 month.\n\nSkills: \n- Project management (Tech) \n- Unity\n- C#\n- Version Control (git)\n- Quality assurance\n- Tooling\n- Wwise API",
      description4: "\nShinobi's Way on Steam",
      description5: "Jessie 'Boom' James on Steam",
      imgUrl: projImg10,
      link: "https://store.steampowered.com/app/1593210/Shinobis_Way__a_jigsaw_chess_tale/?curator_clanid=37117656",
      link2: "https://store.steampowered.com/app/1593220/Jessie_Boom_James__a_jigsaw_chess_tale/?curator_clanid=37117656",
    },
  ];
  const publicProjects = [
    {
      title: "Creep Out",
      description: "4 fun",
      description2: "Brackeys game jam 2021.2 project for practicing the concepts that i learned. Pass through all the processes of a gema development by myself.\nProject duration: 1 week",
      description3: "\nSkills: \n- Project management \n- Unity\n- C#\n- Quality assurance",
      description4: "\nCreep Out on itch.io",
      imgUrl: projImg20,
      link: "https://edy12345.itch.io/creepoutx",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={8}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>In this section you will find all the projects that I've made under contract in these last years; in each one of them you will find a brief description of my contribution and a list of the skills that were used on that project. If you have any other curiosity, feel free to contact me through any of my social medias avaliable on the header.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Contract Projects</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="second">Personal Projects</Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="second">
                    <Row>
                        {
                          publicProjects.map((project, index) => {
                            return (
                              <ProjectCard2
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane> */}
                    {/* <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane> */}
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
