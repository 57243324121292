import React from 'react';
import { useState, useEffect } from "react";

export const Collapsible = (prop) => {
    const [open, setOPen] = useState(false);

    const toggle = () => {
        setOPen(!open);
      };

    return (
      <div className='button5Div'>
        <button  className='button5' onClick={toggle} >{prop.label}</button>
        {open && (<div>
        <p>Personal-portfolio Boilerplate: <a href='https://github.com/judygab/web-dev-projects/tree/main/personal-portfolio'>source</a> | Home banner image: <a href="https://pt.pngtree.com/freepng/flat-design-concept-of-programmers-at-work-mobile-app-software-development-with-characters-can-use-for-web-banner-business-analysis-landing-page-website-template-vector-illustration_5870919.html?sol=downref&id=bef"> source</a> | Home backGround: <a href="https://pt.pngtree.com/freebackground/blue-internet-technology-background_185483.html?sol=downref&id=bef"> source</a></p>
        </div>)}
      </div>
    );
  };

  