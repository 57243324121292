import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import { Collapsible } from './collapsible';
import logo from '../assets/img/Logo3.png';
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon4.png";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/eduardo-silveira-dev/" target="_blank"><img src={navIcon1} alt="" /></a>
              <a href="https://github.com/EduardoSilveira6657" target="_blank"><img src={navIcon2} alt="" /></a>
              <a href="https://www.instagram.com/_dudesilveira/" target="_blank"><img src={navIcon3} alt="" /></a>
            </div>
            {/* <p>Copyright 2022. All Rights Reserved</p> */}
          </Col>
        </Row>
      </Container>
            <Collapsible label = "Credits"/>  
    </footer>
  )
}

//<MailchimpForm />
/* CREDTIS */
//programador PNG foi desenvolvido por RT Studio e vem de https://pt.pngtree.com/freepng/flat-design-concept-of-programmers-at-work-mobile-app-software-development-with-characters-can-use-for-web-banner-business-analysis-landing-page-website-template-vector-illustration_5870919.html?sol=downref&id=bef
//fotos de fundo grátis de https://pt.pngtree.com/freebackground/blue-internet-technology-background_185483.html?sol=downref&id=bef
//http://bit.ly/3H7hs1h