import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const MyVerticallyCenteredModal = (props) => {
    let title = props.title;
    let description = props.description;
    let description2 = props.description2;
    let description3 = props.description3;
    let description4 = props.description4;
    let description5 = props.description5;
    let description6 = props.description6;
    let imgUrl = props.imgUrl;
    let link = props.link;
    let link2 = props.link2;
    let link3 = props.link3;
  return (
    
    <Modal
      {...props}
      className="ModalStyle"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        
    <img src={imgUrl} />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{title}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div>
            <h4>{description}</h4>
            <h5>{description2}</h5>
            <h6>{description3}</h6>
            <p>
            <a href={link} target="_blank"><h6>{description4}</h6></a>
            {link2 != null && (<a href={link2} target="_blank"><h6>{description5}</h6></a>)}
            {link3 != null && (<a href={link3} target="_blank"><h6>{description6}</h6></a>)}
            </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
