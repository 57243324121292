import React from 'react';
import { Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { MyVerticallyCenteredModal } from './centeredModal';

export const ProjectCard = ({ title, description, description2, description3, description4, description5, description6, imgUrl, link, link2, link3 }) => {
  
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <Button variant="primary" onClick={() => setModalShow(true)}>
           Project Details
          </Button>

          <MyVerticallyCenteredModal 
            title={title} 
            description={description}
            description2 = {description2} 
            description3 = {description3} 
            description4 = {description4}
            description5 = {description5}
            description6 = {description6}
            imgUrl={imgUrl} 
            link={link}
            link2={link2}
            link3={link3}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          {/* <a href={link} target="_blank"><h6>{description2}</h6></a> */}
        </div>
      </div>
    </Col>
  )
}
