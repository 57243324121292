import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Collapsible } from './components/collapsible';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Projects />
      <Footer />
      {/* <Skills /> */}
      {/* <Collapsible/>*/}
    </div>
  );
}

export default App;
//<Skills />
//<Contact />
/*
In this Link you can find my portfolio. 




Thumb credits: http://bit.ly/3H7hs1h
 */